
import React, { useEffect } from "react";
import "./StarAndMeteor.css";
import "./card.css";

export const Card = () => {
	useEffect(() => {
		// const init = () => {
		const style = ["style1", "style2", "style3", "style4"];
		const tam = ["tam1", "tam1", "tam2", "tam3"];
		const opacity = [
			"opacity1",
			"opacity1",
			"opacity2",
			"opacity2",
			"opacity3",
		];

		const getRandomArbitrary = (min, max) =>
			Math.floor(Math.random() * (max - min)) + min;
		const getRandomAngle = () => Math.random() * 2 * Math.PI;

		const createStars = () => {
			let star = "";
			const mStars = 3000;

			const starsContainer = document.querySelector(".stars");
			const widthWindow = window.innerWidth;
			const heightWindow = window.innerHeight;

			// Радиус круга, равный половине диагонали окна
			//     const radius = Math.sqrt(widthWindow * widthWindow + heightWindow * heightWindow) / 2;
			const radius = Math.sqrt(
				widthWindow * widthWindow + heightWindow * heightWindow
			);

			// Центр круга
			const centerX = widthWindow / 2;
			const centerY = heightWindow / 2;

			for (let i = 0; i < mStars; i++) {
				// Используем корень квадратный для равномерного распределения по радиусу
				const randomRadius = radius * Math.sqrt(Math.random());
				const angle = getRandomAngle(); // Угол в радианах

				const x = centerX + randomRadius * Math.cos(angle);
				const y = centerY + randomRadius * Math.sin(angle);

				// const delay = (Math.random() * 2).toFixed(2);
				const delay = (Math.random() * (15 - 10) + 10).toFixed(2);

				star += `<span class='star ${style[getRandomArbitrary(0, 4)]} ${
					opacity[getRandomArbitrary(0, 3)]
				} ${tam[getRandomArbitrary(0, 3)]}'
				  style='animation-delay: ${delay}s; left: ${x}px; top: ${y}px;'></span>`;
			}

			starsContainer.innerHTML = star;
		};

		const createMeteor = () => {
			const meteoroStyle = style[getRandomArbitrary(0, 4)];
			document.querySelector(
				".chuvaMeteoro"
			).innerHTML = `<div class='meteoro ${meteoroStyle}'></div>`;

			setTimeout(() => {
				document.querySelector(".chuvaMeteoro").innerHTML = "";
			}, 1000);
		};

		const startMeteorShower = () => {
			const randomDelay = getRandomArbitrary(5000, 10000);
			setTimeout(() => {
				createMeteor();
				startMeteorShower();
			}, randomDelay);
		};

		createStars();
		startMeteorShower();
		// };

		// init();
	}, []);
	return (
		<div className="wrapper">
			<div className="stars"></div>
			<div className="chuvaMeteoro"></div>
		</div>
	);
};
