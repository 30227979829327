
import { Card } from './pages/card';


function App() {
  return (
   <Card/>
  );
}

export default App;
